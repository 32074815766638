import styled from "styled-components";
import { yapeDesktop, yapeMovil, yapeDesktop2, yapeDesktop3 } from "../../../../assets"

export const SecondBannerContainer = styled.div`
  background-color: #fffae6;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  .contents {
    display: flex;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    position: relative;
    justify-content: space-between;
    max-width: 1318px;
    .contentText {
      display: flex;
      flex-direction: column;
      padding-top: 86px;
      padding-left: 98px;
      .title {
        font-family: Lexend;
        font-size: 38px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: 0.0012em;
        text-align: left;
        padding-bottom: 23px;
        color: #ffffff;
        .textRed {
          color: #ff4f00;
        }
      }
      .btnsChange {
        z-index: 2;
        position: relative;
        .btnSlide {
          padding: 16px 20px;
          border-radius: 16px;
          background-color: var(
            --Background-Primary-Default,
            rgba(255, 79, 0, 1)
          );
          font-family: Lexend;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: center;
          color: var(--Text-White-Default, rgba(255, 255, 255, 1));
        }
        .btnSlideSecundary {
          padding: 16px 20px;
          border-radius: 16px;
          border: 1.5px solid var(--Border-Primary-Default, rgba(255, 79, 0, 1));
          font-family: Lexend;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: center;
          color: var(--Text-Primary-Darker, rgba(238, 68, 11, 1));
          background-color: #fff;
          margin-left: 20px;
        }
      }
    }
    .contentImage {
      img {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  @media screen and (min-width: 1701px)  {
    background-image: url(${yapeDesktop3});
    .contents {
      max-width: 1404px;
      .contentText {
        padding-left: 0px;
      }
    }
  }
  
  @media screen and (min-width: 1500px) and (max-width: 1700px) {
    background-image: url(${yapeDesktop2});
    .contents {
      max-width: 1404px;
      .contentText {
        padding-left: 0px;
        .btnSlide {
          padding: 16px 40px !important;
        }
      }
      .contentImage {
        img {
          padding-right: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) and (max-width: 1500px) {
    background-image: url(${yapeDesktop});
    .contents {
      max-width: 1318px;
      .contentText {
        padding-left: 0px;
        .btnSlide {
          padding: 16px 40px !important;
        }
      }
      .contentImage {
        img {
          padding-right: 0px;
        }
      }
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1400px) {
    background-image: url(${yapeDesktop});
    .contents {
      margin: 0px 20px;
      .contentText {
        padding-left: 0px;
        max-width: 800px !important;
        width: 100%;
        .title {
          .textRed {
            display: inline;
          }
        }
        .btnsChange {
          .btnSlide {
            padding: 16px 30px !important;
          }
          .btnSlideSecundary {
            margin-left: 20px !important;
          }
        }
      }
      .contentImage {
        img {
          padding: 0px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    background-image: url(${yapeMovil});
    .contents {
      flex-direction: column;
      .contentText {
        padding-top: 40px;
        padding-left: 24px;
        padding-right: 24px;
        .title {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          padding-bottom: 24px;
          .textRed {
            color: #ff4f00;
            display: inline;
          }
        }
        .btnsChange {
          .btnSlide {
            width: 259px;
            padding: 12px 20px;
          }
          .btnSlideSecundary {
            padding: 11px 20px;
            margin-left: 0px;
            margin-top: 16px;
            width: 259px;
          }
        }
      }
      .contentImage {
        position: relative;
        height: 229.01px;
        display: flex;
        align-items: center;
        img {
          position: relative;
          width: 100%;
          right: auto;
          bottom: 0px;
          padding: 0px;
        }
      }
    }
  }
`;

import styled from "styled-components"
import { wfDesktop, wfMovil, wfDesktop2, wfDesktop3 } from "../../../../assets"

export const FourthBannerContainer = styled.div`
  height: 457px;
  width: 100%;
  background-image: url(${wfDesktop});
  background-size: 100% 100%;
  .contents {
    display: flex;
    margin: 0 auto;
    position: relative;
    
    .contentText {
      width: 50%;
      padding-top: 92px;
      h1 {
        font-family: Lexend;
        font-size: 38px;
        font-weight: 600;
        line-height: 50px;
        letter-spacing: 0.0012em;
        text-align: left;
        margin: 0px;
        padding-bottom: 16px;
        color: #ffffff;
      }
      .subTitle {
        font-family: Lexend;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.0012em;
        text-align: left;
        color: #ffffff;
      }
      .btnsChange {
        display: flex;
        padding-top: 36px;
        z-index: 2;
        position: relative;
        .btnSlide {
          padding: 16px 20px;
          border-radius: 16px;
          background-color: var(
            --Background-Primary-Default,
            rgba(255, 79, 0, 1)
          );
          border: 1.5px solid var(--Border-Primary-Default, rgba(255, 79, 0, 1));
          font-family: Lexend;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: center;
          color: var(--Text-White-Default, rgba(255, 255, 255, 1));
        }
        .btnSlideSecundary {
          padding: 16px 20px;
          border-radius: 16px;
          border: 1.5px solid var(--Border-Primary-Default, rgba(255, 79, 0, 1));
          font-family: Lexend;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.0012em;
          text-align: center;
          color: var(--Text-Primary-Darker, rgba(238, 68, 11, 1));
          background-color: #fff;
          margin-left: 20px;
        }
      }
    }
    .contentComents {
      display: flex;
      padding-top: 14px;
      width: 50%;
      justify-content: end;
    }
  }

  @media only screen and (max-width: 540px) {
    height: 604px;
    background-image: url(${wfMovil});
    .contents {
      display: flex;
      flex-direction: column;
      .contentText {
        padding: 24px;
        margin-top: 16px;
        width: 100%;
        h1 {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          text-align: left;
          padding-bottom: 8px;
        }
        .subTitle {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
          max-width: 220px;
        }
        .btnsChange {
          padding-top: 24px;
          flex-direction: column;
          .btnSlide {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.0012em;
            text-align: center;
            max-width: 259px;
            margin-bottom: 16px;
            padding: 12px 20px;
            border: none;
            border-radius: 12px;
          }
          .btnSlideSecundary {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin-left: 0px;
            padding: 12px 20px;
            max-width: 259px;
            border-radius: 12px;
          }
        }
      }
      .contentComents {
        width: 100%;
        justify-content: center;
        padding-top: 12px;
      }
    }
  }

  @media only screen and (min-width: 500px) and (max-width: 1366px) {
    background-image: url(${wfDesktop});
    .contents {
      margin: 0px 20px;
        .btnSlide {
          padding: 16px 30px !important;
        }
    }
  }

  @media screen and (min-width: 1365px) and (max-width: 1727px) {
    background-image: url(${wfDesktop});
    .contents {
      max-width: 1318px;
        .btnSlide {
          padding: 16px 40px !important;
        }
    }
  }


  @media screen and (min-width: 1600px) and (max-width: 1727px) {
    background-image: url(${wfDesktop2});
  }

  @media screen and (min-width: 1500px) {
    .contents {
      max-width: 1404px !important;
    }
  }

  @media screen and (min-width: 1728px) {
    background-image: url(${wfDesktop3});
        .btnSlide {
          padding: 16px 40px !important;
        }
  }
`;


export const GradientOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 207px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.13));
  z-index: 1; 
  @media only screen and (max-width: 540px) {
    height: 124px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 11.6%, rgba(0, 0, 0, 0.13) 81.82%);

  }
`;
import React from "react";
import FirstBanner from "../components/banners/firstBanner/firstBanner";
import SecondBanner from "../components/banners/secondBanner/secondBanner";
import ThirdBanber from "../components/banners/thirdBanner/thirdBanber";
import FourthBanner from "../components/banners/fourthBanner/fourthBanner";
import FifthBanner from "../components/banners/fifthBanner/fifthBanner";
import MainSlider from "../components/mainSlider/mainSlider";
import Headband from "../components/headband/headband";
const slides = [
  {
    content: <FirstBanner />,
  },
  {
    content: <SecondBanner />,
  },
  {
    content: <ThirdBanber />,
  },
  {
    content: <FourthBanner />,
  },
  {
    content: <FifthBanner />,
  },
];
export const ExperimentA = () => {
  return (
    <>
      <Headband />
      <MainSlider slides={slides} />
    </>
  );
};

import styled from "styled-components";
export const StyledDotsWrapper = styled.ul`
  list-style: none;
  display: flex !important;
  position: absolute;
  bottom: 40px;
  left: 99.5px;
  justify-content: flex-start;
  align-items: center;
  max-width: 1404px;
  transform: translateY(50%);
  @media (min-width: 1500px) {
    left: 50%;
    transform: translate(-50%, 50%);
  }
  @media screen and (min-width: 768px) and (max-width: 1500px) {
    left: 52%;
    transform: translate(-50%, 50%);
  }
  @media (max-width: 768px) {
    bottom: 20px;
    justify-content: center;
    left: 0;
    width: 100%;
  }

  li {
    /* margin: 0 6px; */
    display: flex;
    align-items: center;
    button {
      width: 14px;
      height: 14px;
      background-color: #FFFFFF;
      border-radius: 50%;
      border: none;
      opacity: 0.6;
      transition: all 0.3s ease;
      box-shadow: 0px 2px 4px 0px #45494E0A;
      transform: scale(1);
      &:before {
        display: none;
      }
    }

    &.slick-active button {
      opacity: 1;
      box-shadow: 0px 16px 32px -4px #45494E1A;
      background-color: #FFFFFF;
      transform: scale(1.4);
      transition: all 0.3s ease;
    }
  }
`;

// Contenedor para cada slide
export const Slide = styled.div`
  height: 417px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: ${(props) => props.bgColor || "lightgray"};
  @media screen and (max-width: 900px) {
    height: 525px;
  }
`;
